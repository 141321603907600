import { GlobalPrimaryBannerPicture } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/GlobalPrimaryBannerPicture'
import {
  GlobalPrimaryBannerWithList,
  GlobalPrimaryBannerWithTextOverlay,
  GravityPicture,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  BannerProps,
  BoxHorizontalAlignment,
  TextHorizontalAlignment,
} from '@thg-commerce/enterprise-widget-banner'
import { ButtonEmphases } from '@thg-commerce/gravity-elements'

interface PrimaryBannerWithList
  extends Omit<GlobalPrimaryBannerWithList, 'id' | 'query'> {
  useH1?: string
}

const getButtonEmphasis = (contentTheme, ctaOverride) => {
  if (contentTheme === 'dark' || ctaOverride === 'dark') {
    return 'high'
  }
  return 'medium'
}

export const getPrimaryBannerProps = (
  banner:
    | GlobalPrimaryBannerPicture
    | (Omit<GlobalPrimaryBannerWithTextOverlay, 'query'> & {
        picture: GravityPicture
      })
    | (Omit<PrimaryBannerWithList, 'query'> & { picture: GravityPicture }),
): BannerProps => {
  return {
    picture: banner.picture,
    imageLink: banner.bannerURL || undefined,
    logoImage: {
      default: banner.logopngImageBG,
      white: banner.logopngWhiteBG,
      altText: banner?.altImageLarge || undefined,
    },
    title:
      (banner.headline && {
        text: banner.headline,
        headingLevel: banner.useH1 && banner.useH1 === 'true' ? 'h1' : 'h2',
      }) ||
      undefined,
    ctas:
      banner.ctaOne && banner.ctaOneURL
        ? [
            {
              text: banner.ctaOne,
              link: banner.ctaOneURL,
              emphasis: getButtonEmphasis(
                banner.contentTheme,
                banner.ctaOverride,
              ) as ButtonEmphases,
            },
            ...(banner.ctaTwo && banner.ctaTwoURL
              ? [
                  {
                    text: banner.ctaTwo,
                    link: banner.ctaTwoURL,
                    emphasis: getButtonEmphasis(
                      banner.contentTheme,
                      banner.ctaOverride,
                    ) as ButtonEmphases,
                  },
                ]
              : []),
          ]
        : undefined,
    subtitle: banner.subtitle || '',
    textColor: banner.contentTheme === 'light' ? 'light' : 'dark',
    textContainerOptions: {
      width: 'full',
      contentAlignment: {
        box: (banner.contentBoxPosition as BoxHorizontalAlignment) || 'middle',
        text: (banner.contentAlign as TextHorizontalAlignment) || 'center',
      },
    },
  }
}
